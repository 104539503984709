import { PhArrowUp, PhArrowDown } from 'phosphor-vue'

export const EMAIL_RE = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/

export const ITEM_LAYOUT_STYLE = {
  cards: 'cards',
  tiles: 'tiles',
  list: 'list',
  headlines: 'headlines',
}

export const BOOKMARK_SORT_FIELDS = {
  createdAt: 'createdAt',
  title: 'title',
  domain: 'sld',
  commentsNumber: 'commentsNumber',
  commentsDate: 'commentsDate',
  likes: 'likes',
}

export const BOOKMARK_SORT_OPTIONS = [
  {
    field: BOOKMARK_SORT_FIELDS.createdAt,
    asc: false,
  },
  {
    field: BOOKMARK_SORT_FIELDS.createdAt,
    asc: true,
  },
  {
    field: BOOKMARK_SORT_FIELDS.title,
    asc: true,
  },
  {
    field: BOOKMARK_SORT_FIELDS.title,
    asc: false,
  },
  {
    field: BOOKMARK_SORT_FIELDS.domain,
    asc: true,
  },
  {
    field: BOOKMARK_SORT_FIELDS.domain,
    asc: false,
  },
  {
    field: BOOKMARK_SORT_FIELDS.commentsNumber,
    asc: false,
  },
  {
    field: BOOKMARK_SORT_FIELDS.commentsNumber,
    asc: true,
  },
  {
    field: BOOKMARK_SORT_FIELDS.commentsDate,
    asc: false,
  },
  {
    field: BOOKMARK_SORT_FIELDS.commentsDate,
    asc: true,
  },
  {
    field: BOOKMARK_SORT_FIELDS.likes,
    asc: false,
  },
  {
    field: BOOKMARK_SORT_FIELDS.likes,
    asc: true,
  },
]

export const BOOKMARK_SORT_OPTIONS_UI = {
  [`${BOOKMARK_SORT_FIELDS.createdAt}-desc`]: {
    string: 'component.bookmarks.sorting.field.createdAt.desc',
    icon: PhArrowDown,
  },
  [`${BOOKMARK_SORT_FIELDS.createdAt}-asc`]: {
    string: 'component.bookmarks.sorting.field.createdAt.asc',
    icon: PhArrowUp,
  },

  [`${BOOKMARK_SORT_FIELDS.title}-asc`]: {
    string: 'component.bookmarks.sorting.field.title.asc',
    icon: PhArrowDown,
  },
  [`${BOOKMARK_SORT_FIELDS.title}-desc`]: {
    string: 'component.bookmarks.sorting.field.title.desc',
    icon: PhArrowUp,
  },

  [`${BOOKMARK_SORT_FIELDS.domain}-desc`]: {
    string: 'component.bookmarks.sorting.field.domain.desc',
    icon: PhArrowUp,
  },
  [`${BOOKMARK_SORT_FIELDS.domain}-asc`]: {
    string: 'component.bookmarks.sorting.field.domain.asc',
    icon: PhArrowDown,
  },

  [`${BOOKMARK_SORT_FIELDS.commentsNumber}-desc`]: {
    string: 'component.bookmarks.sorting.field.commentsNumber.desc',
    icon: PhArrowDown,
  },
  [`${BOOKMARK_SORT_FIELDS.commentsNumber}-asc`]: {
    string: 'component.bookmarks.sorting.field.commentsNumber.asc',
    icon: PhArrowUp,
  },

  // String key swap for comment date is intentional
  [`${BOOKMARK_SORT_FIELDS.commentsDate}-desc`]: {
    string: 'component.bookmarks.sorting.field.commentsDate.asc',
    icon: PhArrowDown,
  },
  [`${BOOKMARK_SORT_FIELDS.commentsDate}-asc`]: {
    string: 'component.bookmarks.sorting.field.commentsDate.desc',
    icon: PhArrowUp,
  },

  [`${BOOKMARK_SORT_FIELDS.likes}-asc`]: {
    string: 'component.bookmarks.sorting.field.likes.asc',
    icon: PhArrowUp,
  },
  [`${BOOKMARK_SORT_FIELDS.likes}-desc`]: {
    string: 'component.bookmarks.sorting.field.likes.desc',
    icon: PhArrowDown,
  },
}

export const COLLECTION_SORT_FIELDS = {
  createdAt: 'createdAt',
  name: 'name',
  newestItem: 'newestItem',
  oldestItem: 'oldestItem',
  title: 'title', // for items view sorting
}

export const COLLECTION_SORT_OPTIONS = [
  {
    field: COLLECTION_SORT_FIELDS.createdAt,
    asc: false,
  },
  {
    field: COLLECTION_SORT_FIELDS.createdAt,
    asc: true,
  },
  {
    field: COLLECTION_SORT_FIELDS.name,
    asc: true,
  },
  {
    field: COLLECTION_SORT_FIELDS.name,
    asc: false,
  },
  {
    field: COLLECTION_SORT_FIELDS.newestItem,
    asc: true,
  },
  {
    field: COLLECTION_SORT_FIELDS.oldestItem,
    asc: true,
  },
]

export const COLLECTION_SORT_OPTIONS_UI = {
  [`${COLLECTION_SORT_FIELDS.createdAt}-desc`]: {
    string: 'component.collections.sorting.field.createdAt.desc',
    icon: PhArrowDown,
  },
  [`${COLLECTION_SORT_FIELDS.createdAt}-asc`]: {
    string: 'component.collections.sorting.field.createdAt.asc',
    icon: PhArrowUp,
  },

  [`${COLLECTION_SORT_FIELDS.name}-asc`]: {
    string: 'component.collections.sorting.field.title.asc',
    icon: PhArrowDown,
  },
  [`${COLLECTION_SORT_FIELDS.name}-desc`]: {
    string: 'component.collections.sorting.field.title.desc',
    icon: PhArrowUp,
  },

  [`${COLLECTION_SORT_FIELDS.newestItem}-asc`]: {
    string: 'component.collections.sorting.field.itemDate.desc',
    icon: PhArrowDown,
  },
  [`${COLLECTION_SORT_FIELDS.oldestItem}-asc`]: {
    string: 'component.collections.sorting.field.itemDate.asc',
    icon: PhArrowUp,
  },
}

export const MAX_READ_TIME = 60

export const bookmarkFilterDefaults = () => ({
  search: '',
  tags: [],
  isFavorite: false,
  sorting: [BOOKMARK_SORT_FIELDS.createdAt, false],
  readTime: [0, MAX_READ_TIME],
  languages: [],
})

export const collectionBookmarkFilterDefaults = () => ({
  search: '',
  tags: [],
  sorting: [BOOKMARK_SORT_FIELDS.createdAt, false],
  readTime: [0, MAX_READ_TIME],
  languages: [],
})

export const collectionsFilterDefaults = () => ({
  search: '',
  sorting: [COLLECTION_SORT_FIELDS.createdAt, false],
})

export const STALE_TIME = 300e3

export const INVITE_LINK_RE = /^\/collections\/([a-f\d]+)\/invite-(link|email)\/([a-z0-9-]+)/i

export const THEME_NAMES = {
  auto: 'page.account.app_settings.theme.auto',
  light: 'page.account.app_settings.theme.light',
  dark: 'page.account.app_settings.theme.dark',
}

export const STYLE_NAMES = {
  [ITEM_LAYOUT_STYLE.cards]: 'page.account.app.layout.cards.title',
  [ITEM_LAYOUT_STYLE.list]: 'page.account.app.layout.lists.title',
  [ITEM_LAYOUT_STYLE.headlines]: 'page.account.app.layout.headlines.title',
  [ITEM_LAYOUT_STYLE.tiles]: 'page.account.app.layout.tiles.title',
}

export const MAX_IMPORT_LIMIT = 1000

export const AddToCollectionMethod = {
  MOVE: 'move',
  COPY: 'copy',
}

export const HOW_TO_UPDATE_APP_LINKS = {
  android: {
    en: 'https://rubra.com/help/updating-rubra/android-native-app/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-nativen-android-app/',
  },
  ios: {
    en: 'https://rubra.com/help/updating-rubra/ios-native-app/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-nativen-ios-app/',
  },
  firefox: {
    en: 'https://rubra.com/help/updating-rubra/firefox-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisierung-der-firefox-browsererweiterung/',
  },
  safari: {
    en: 'https://rubra.com/help/updating-rubra/safari-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-safari-browsererweiterung/',

  },
  edge: {
    en: 'https://rubra.com/help/updating-rubra/edge-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-edge-browsererweiterung/',
  },
  chrome: {
    en: 'https://rubra.com/help/updating-rubra/chrome-browser-extension/',
    de: 'https://rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-chrome-browsererweiterung/',
  },
  // Web-App (Should never show as it is updated automatically)
  web: {
    en: 'rubra.com/help/updating-rubra/web-app/',
    de: 'rubra.com/de/aktualisierung-von-rubra/aktualisieren-der-web-app/',
  },
}

export const UPDATE_APP_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=com.rubra.appclient',
  ios: 'https://apps.apple.com/app/rubra-bookmark-manager/id1673761012',
  firefox: 'about:addons',
  safari: 'safari-extension://',
  edge: 'edge://extensions/',
  chrome: 'chrome://extensions/',
}

export const UPDATE_NEWS_LINKS = {
  de: 'https://rubra.com/de/update/',
  en: 'https://rubra.com/update/',
}

export const HELP_LINKS = {
  addBookmark: {
    en: 'https://rubra.com/help/using-rubra/add-items/adding-bookmarks/',
    de: 'https://rubra.com/de/eintrage-hinzufugen/hinzufuegen-von-lesezeichen/',
  },
}

export const DEFAULT_DURATION = '30d'
export const DEACTIVATION_DURATION = '-1h'

export const MAX_COLLECTIONS_DEEPNESS = 1
